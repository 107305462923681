import request from '@/utils/request'

const goalApi = {
    update: '/api/goal',
    list: '/api/goals',
    get: '/api/goal/',
    deletegoal: '/api/goal/',
    addorupdateaction: '/api/goalaction',
    getaction: '/api/goalactiondata/',
    getgoaldetail:'/api/goaldetail/',
    removeactionfromgoal: '/api/removeactionfromgoal',
    getproductgoals: '/api/productgoals/'
}

export function getproductgoals(productid) {
    return request({
        url: goalApi.getproductgoals + productid,
        method: 'get'
    });
}

export function removeactionfromgoal(data) {
    return request({
        url: goalApi.removeactionfromgoal,
        method: 'post',
        data: data
    });
}

export function getaction(goalid,actionid) {
    return request({
        url: goalApi.getaction + goalid+'/' +actionid,
        method: 'get'
    });
}

export function addorupdateaction (parameter) {
    return request({
        url: goalApi.addorupdateaction,
        method: 'post',
        data: parameter
    })
}

export function addorupdate (parameter) {
    return request({
        url: goalApi.update,
        method: 'post',
        data: parameter
    })
}

export function list() {
    return request({
        url: goalApi.list,
        method: 'get'
    });
}

export function getitem(id) {
    return request({
        url: goalApi.get + id,
        method: 'get'
    });
}

export function deletegoal(id) {
    return request({
        url: goalApi.deletegoal + id,
        method: 'delete'
    });
}

export function getdetail(id) {
    return request({
        url: goalApi.getgoaldetail + id,
        method: 'get'
    });
}