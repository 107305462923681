<template>
     
    <a-card
    :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}"
    > 
       <a-card :bordered="false" class="dashboard-bar-chart">
            <div class="card-title">
			  <h5>{{productname2}}</h5>
		    </div>         
        </a-card>
        <a-table :columns="urlcolumn" :data-source="urldata" :pagination="true">
            <template slot="delBtn" slot-scope="row">
				<a-button shape="circle" @click="delurl(row)" icon="delete">
				</a-button>
			</template>
        </a-table>

        <a-table :columns="goalcolumn" :data-source="goals" :pagination="true">
            <template slot="editBtn" slot-scope="row">
				<a-button shape="circle" @click="editgoal(row)" icon="edit">
				</a-button>
			</template>
            <template slot="delBtn" slot-scope="row">
				<a-button shape="circle" @click="delgoal(row)" icon="delete">
				</a-button>
			</template>
            <template slot="navBtn" slot-scope="row">
				<a-button shape="circle" @click="navgoal(row)" icon="right">
				</a-button>
			</template>
        </a-table>
        <br/>
        <a-button icon="plus" size="small" danger @click="addgoal()">Add goal</a-button>

        <a-card :bordered="false" class="dashboard-bar-chart">
            <div class="card-title">
		 	  <h6>Price comparison</h6>
			  <p>Historical price changes during a time</p>
		    </div>
             <chart-line :height="500" :data="chartdata"></chart-line>
             
        </a-card>
        <a-card :bordered="false" class="dashboard-bar-chart">
            <div class="card-title">
		 	  <h6>Most correlated products sold with this product</h6>
			  <p>When customer buys this product, how many others are sold together</p>
		    </div>
             <chart-bar :height="400" :data="barChartData1"></chart-bar>
             
        </a-card>
        <!-- <a-card :bordered="false" class="dashboard-bar-chart">
            <div class="card-title">
		 	  <h6>Correlation</h6> 
			  <p>Percentage of correlation</p>
		    </div>
             <chart-bar :height="500" :data="barChartData1"></chart-bar>
        </a-card> -->
        <a-card :bordered="false" class="dashboard-bar-chart">
            <div class="card-title">
		 	  <h6>Sales revenue</h6>
			  <p>pos data revenue for the product</p>
		    </div>
             <chart-line :height="500" :data="salesdata" :enablelegend="0"></chart-line>
             
        </a-card>
        <a-card :bordered="false" class="dashboard-bar-chart">
            <div class="card-title">
		 	  <h6>Price change simulation</h6>
			  <p>change your price here</p>
		    </div>
            <a-form :form="form" @submit="handleSubmit">
                <a-form-item label="Price simulation">
                    <a-input
                    v-decorator="[
                        'price'
                    ]"
                    />
                </a-form-item>
                <a-button type="primary" html-type="submit">
                    Submit
                </a-button>
            </a-form>
        </a-card>

        <a-button class="fab" shape="circle" icon="plus" @click="addurl"></a-button>
        <a-button class="fab" shape="circle" style="right:100px" @click="aiproduct">AI</a-button>
    </a-card>
</template>
<script>
    import {getitem, getpricedata, getcorrdata, getsalesforproduct,simulate, deleteurl, deleteproductgoal} from '@/api/product';
    import ChartLine from '@/components/Charts/ChartLine' ;
    import ChartBar from '@/components/Charts/ChartBar' ;
    import {getproductgoals} from '@/api/goal';

    export default ({
        components: {
			ChartLine,
            ChartBar,
		},
        props: {
            productid:String,
            productname: String
        },
        data() {
            return {
                product:{},
                urldata: [],
                urlcolumn: [
                    {title:'Competitors URL',dataIndex:'item.url'},
                    {title:'', dataIndex:'item.id' ,scopedSlots: { customRender: 'delBtn' }, width: 40,}
                ],
                goalcolumn:[
                    {title:'Goal', dataIndex:'name'},
                    {title:'', dataIndex:'goalid' , key:'goalid2',scopedSlots: { customRender: 'editBtn'},width: 40 },
                    {title:'', dataIndex:'goalid' ,scopedSlots: { customRender: 'delBtn' },width: 40},
                    {title:'', dataIndex:'goalid' , key:'goalid3',scopedSlots: { customRender: 'navBtn' },width: 40}
                ],
                chartdata:{},
                legends:[],
                barChartData: {},
                barChartData1: {},
                salesdata:{},
                productname2:'',
                goals:[],
                form: this.$form.createForm(this,{name: 'simulation of pricechange'})
                
            }
        },
        mounted(){
            getitem(this.productid).then(data=>{
                this.product = data;
                this.productname2 = this.product.name;
                this.urldata = [];
                if (data.urls){
                    for(var i=0;i<data.urls.length;i++)
                        this.urldata.push({item:data.urls[i]});
                }
                this.goals=data.goals;
                getproductgoals(this.productid).then(data=>{
                    
                })

                getcorrdata(this.productid).then(result=>{
                    
                    var datacount=[]
                    var labels = [];
                    var percent = [];
                    for (var i=0;i<result.length;i++)
                    {
                        var item = result[i];
                        
                        datacount.push(item.count)
                        labels.push(item.ID2)
                        percent.push(item.percentage)
                      
                    }
                   
                    var datasets = [
                        {
                        label: "Sales",
						backgroundColor: ['#9A89FF','#00D69E'],
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: datacount,
						maxBarThickness: 20,
                       
                        }

                    ];
                    var datasets1 = [
                        {
                        label: "Percentage",
						backgroundColor: ['#9A89FF','#00D69E'],
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: percent,
						maxBarThickness: 20,
                       
                        }

                    ];
                   
                    this.barChartData={
                        labels: labels,
                        datasets: datasets
                    };

                    this.barChartData1={
                        labels: labels,
                        datasets: datasets1
                    };
                   
                });

                getpricedata(this.productid).then(result=>{
                    var datasets = [];
                    var labels = [];
                    for (var i=0;i<result.length;i++)
                    {
                        var item = result[i];
                        var itemColor = this.getRandomColor();
                        var rawdata = [];
                        for (var j=0;j<item.item.length;j++)
                        {
                            rawdata.push(item.item[j].price)
                            if (i==0)
                            {
                                labels.push(item.item[j].timestamp.substring(0,10));
                            }
                        }
                        var dataitem = {
                            label: item.name,
                            tension: 0.4,
                            borderWidth: 0,
                            pointRadius: 0,
                            borderWidth: 3,
                            borderColor: ["#00D69E","#9A89FF", '#FFB636','#FF005D'],
                            data: rawdata,
                            maxBarThickness: 6
                        };
                        datasets.push(dataitem);
                    }
                    this.chartdata={
                        labels: labels,
                        datasets: datasets
                    };
                });

                getsalesforproduct(this.productid).then(salesresult=>{
                    var datasets = [];
                    var labels = [];
                    var rawdata = [];
                    for (var i=0;i<salesresult.length;i++)
                    {
                        var item = salesresult[i];
                        var itemColor = this.getRandomColor();
                        
                        labels.push(item.key_as_string.substring(0,10));
                        rawdata.push(item.doc_count);                        
                        
                    }
                    var dataitem = {
                            label: 'sales revenue',
                            tension: 0.4,
                            borderWidth: 0,
                            pointRadius: 0,
                            borderWidth: 3,
                            borderColor: "#00D69E",
                            data: rawdata,
                            maxBarThickness: 6
                    };
                    datasets.push(dataitem);
                    this.salesdata={
                        labels: labels,
                        datasets: datasets
                    };
                });
            })
        },
        methods: {
            addurl(){
                this.$router.push({path:'/addurl',query:{productid:this.productid}});
            },
            delurl(id){
                deleteurl({url:id,id:this.productid}).then(data=>{
                    for (var i=0;i<this.urldata.length;i++){
                        console.log(this.urldata[0].item)
                        if (this.urldata[i].item.id==id)
                        {
                            this.urldata.splice(i,1);
                            break;
                        }
                    }
                })
            },
            aiproduct(){
                this.$router.push({path: 'productaisuggestion', query:{productid:this.productid}});
            },
            handleSubmit(e){
                e.preventDefault();
                this.form.validateFields((err,values)=>{
                    if (!err){

                        simulate(values.price,this.productid,this.product.name)
                    }
                });
            },
            getRandomColor() {
                var letters = '0123456789ABCDEF'.split('');
                var color = '#';
                for (var i = 0; i < 6; i++ ) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            },
            addgoal(){
                this.$router.push({path:'/goaledit',query:{goalid:0,categoryid:this.product.productKey,type:2}});
            },
            delgoal(id){
                deleteproductgoal({productid:this.product.productKey,goalid:id}).then(data=>{
					for (var i=0; i < this.goals.length; i++)
						if (this.goals[i].goalid==id)
							this.goals.splice(i,1);
				})
            },
            editgoal(id){
                this.$router.push({path:'/goaledit',query:{goalid:id,categoryid:this.product.productKey,type:2}});
            },
            navgoal(id){
                this.$router.push({path:'/goaldetail',query:{goalid:id,categoryid:this.product.productKey,type:2}});

            }
        }
    });
</script>